import type { StyleRule } from '@archipro-design/aria';
import { MOBILE_TOP_NAV_HEIGHT_SHORT_LOGO } from '@archipro-design/aria';
import { pxArrayToRem, pxToRem } from '@archipro-design/aria';

const DRAWER_HEADER_HEIGHT = 72;

export const BottomDrawerTitle: StyleRule = () => ({
    textTransform: 'uppercase',
    padding: pxArrayToRem([0, 18]),
    textAlign: 'center',
    marginBottom: pxToRem(24),
    marginTop: 0,
});

export const BottomDrawerContentContainer: StyleRule = () => ({
    height: `calc(100vh - ${pxToRem(DRAWER_HEADER_HEIGHT)} - ${pxToRem(
        MOBILE_TOP_NAV_HEIGHT_SHORT_LOGO
    )})`, // Allow drawer content to be size of the screen minus the top nav and and drawer header
    '@supports (height: 100dvh)': {
        height: `calc(100dvh - ${pxToRem(DRAWER_HEADER_HEIGHT)} - ${pxToRem(
            MOBILE_TOP_NAV_HEIGHT_SHORT_LOGO
        )})`,
    },
    overflow: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
    '& > div': {
        paddingBottom: pxToRem(18),
    },
});
