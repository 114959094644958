import { useEffect, useState, useRef } from 'react';

interface UseFacebookAutofillProps {
    open: boolean;
    keepOpenDuration?: number;
}

/**
 * A hook that helps handle Facebook browser form autofill behavior.
 * When a form drawer is opened in the Facebook in-app browser
 * and inputs are auto-filled,
 * this hook will keep the form open for a specified duration to allow the autofill to complete.
 *
 * @param props.open - Whether the form/modal is currently open
 * @param props.keepOpenDuration - How long to keep the form open after inputs are filled (default: 2000ms), ignored the close event
 * @returns Object containing mustKeepOpen flag indicating if the form should stay open
 */
export const useFacebookAutofill = ({
    open,
    keepOpenDuration = 2000,
}: UseFacebookAutofillProps) => {
    const isOpenInFacebookBrowser = isFacebookBrowser() && open;
    const [mustKeepOpen, setMustKeepOpen] = useState(false);
    const hasBeenAutoFilled = useRef(false);

    useEffect(() => {
        // If not opened in Facebook browser, do nothing
        if (!isOpenInFacebookBrowser) return;

        const handleInput = (e: Event) => {
            if (hasBeenAutoFilled.current) {
                // Auto-fill only happens once
                return;
            }

            const target = e.target as HTMLInputElement;

            // If it's a manual input (has inputType), remove the listener immediately
            // Autofill inputs don't have inputType
            if ((e as InputEvent).inputType) {
                document.removeEventListener('input', handleInput, true);
                return;
            }

            const form = target.closest('form');

            if (form) {
                const formInputs = form.querySelectorAll('input');
                const filledInputs = Array.from(formInputs).filter(
                    (input) => input.value || input.matches(':-webkit-autofill')
                );
                if (filledInputs.length > 0) {
                    setMustKeepOpen(true);
                    hasBeenAutoFilled.current = true;
                }
            }

            if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
                if (!!target.value && !mustKeepOpen) {
                    setMustKeepOpen(true);
                    hasBeenAutoFilled.current = true;
                }
            }
        };

        document.addEventListener('input', handleInput, true);

        let timeout: ReturnType<typeof setTimeout> | undefined;
        if (mustKeepOpen || hasBeenAutoFilled.current) {
            document.removeEventListener('input', handleInput, true);
            timeout = setTimeout(() => {
                setMustKeepOpen(false);
            }, keepOpenDuration);
        }

        return () => {
            document.removeEventListener('input', handleInput, true);
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [isOpenInFacebookBrowser, keepOpenDuration, mustKeepOpen]);

    return { mustKeepOpen, isOpenInFacebookBrowser };
};

export const isFacebookBrowser = () => {
    return (
        typeof window !== 'undefined' &&
        /FBAN|FBAV/.test(window.navigator.userAgent)
    );
};
